#block-newsletter {
  position: relative;
  padding: 15px;
  color: #fff; }
  #block-newsletter .wrapper {
    padding: 30px 0;
    background-color: #D33484;
    background-image: url(../../../img/bg_newsletter.jpg);
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: auto 100%; }
  #block-newsletter h4 {
    text-transform: uppercase;
    font-size: 2.6rem; }
  #block-newsletter .right, #block-newsletter .left {
    padding: 0 15px 15px 15px; }
  #block-newsletter .right {
    position: relative; }
    #block-newsletter .right .form-group {
      display: block;
      position: relative;
      padding: 0px; }
  #block-newsletter .form-control.newsletter-input {
    padding: 15px 25px;
    border: 0;
    height: auto;
    line-height: 2.5rem;
    font-size: 18px; }
    #block-newsletter .form-control.newsletter-input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px #ddd inset !important;
      -webkit-text-fill-color: #fff !important; }
  #block-newsletter .btn {
    height: 5.5rem;
    font-weight: bold;
    text-transform: uppercase;
    padding: 0 20px; }
    #block-newsletter .btn .fa {
      font-size: 22px; }
  #block-newsletter .loading {
    position: absolute;
    z-index: 6090;
    background: rgba(200, 200, 200, 0.7);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    text-align: center;
    transform: scale(0);
    opacity: 0; }
    #block-newsletter .loading > div {
      position: relative;
      top: 50%;
      transform: translateY(-50%); }
    #block-newsletter .loading .fa {
      color: #000;
      font-size: 80px; }
  #block-newsletter.load .loading {
    transform: scale(1.1);
    transition: all .3s;
    opacity: 1; }
  #block-newsletter .message {
    font-size: 14px;
    display: none;
    text-transform: none; }

#block-newsletter-modal label {
  color: #888; }

@media (min-width: 992px) {
  #block-newsletter .left, #block-newsletter .right {
    float: left; }
  #block-newsletter .left {
    width: 40%; }
  #block-newsletter .right {
    width: 60%; } }
